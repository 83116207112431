import React, { Suspense, lazy } from 'react'
import { Routes, Route, createBrowserRouter, RouterProvider } from 'react-router-dom'
import AuthLayout from './AuthLayout'

import Loading from '../components/Loading'

const SignIn = lazy(() => import('../pages/Auth/SignIn'))
const Recover = lazy(() => import('../pages/Auth/Recover'))
const PasswordChange = lazy(() => import('../pages/Auth/PasswordChange'))

const NotFound = lazy(() => import('../pages/404'))

const Home = lazy(() => import('../pages/Home'))
const Monitor = lazy(() => import('../pages/Monitor'))
const Config = lazy(() => import('../pages/Config'))
const RequestIndex = lazy(() => import('../pages/Request/Index'))
const RequestView = lazy(() => import('../pages/Request/View'))

// USERS
const UserIndex = lazy(() => import('../pages/User/Index'))
const UserForm = lazy(() => import('../pages/User/Form'))
const UserView = lazy(() => import('../pages/User/View'))

// COMPANIES
const CompanyIndex = lazy(() => import('../pages/Company/Index'))
const CompanyForm = lazy(() => import('../pages/Company/Form'))
const CompanyView = lazy(() => import('../pages/Company/View'))

const MyRoutes = () => (
  <Suspense fallback={<Loading />}>
    <Routes>
      <Route element={<AuthLayout login />}>
        <Route index element={<SignIn />} />
        <Route path='*' component={NotFound} />
      </Route>

      <Route element={<AuthLayout />}>
        <Route path='/recuperar' exact element={<Recover />} />
      </Route>

      <Route element={<AuthLayout isPrivate />}>
        <Route path='/inicio' exact element={<Home />} />

        <Route path='/trocar-senha' exact element={<PasswordChange />} />

        <Route path='/pedidos' exact element={<RequestIndex />} />
        <Route path='/pedido/:protocol' exact element={<RequestView />} />
      </Route>

      <Route element={<AuthLayout isPrivate viewer='admin' superOnly />}>
        <Route path='/configuracoes' exact element={<Config />} />
      </Route>

      <Route element={<AuthLayout isPrivate sideBar={false} />}>
        <Route path='/empresas' exact element={<CompanyIndex />} />
        <Route path='/empresa/:id' exact element={<CompanyView />} />
        <Route path='/empresa/nova' exact element={<CompanyForm />} />
      </Route>

      <Route element={<AuthLayout isPrivate sideBar={false} />}>
        <Route path='/usuarios' exact element={<UserIndex />} />
        <Route path='/usuario/:username' exact element={<UserView />} />
        <Route path='/usuario/novo' exact element={<UserForm />} />
      </Route>

      <Route element={<AuthLayout isPrivate sideBar={false} />}>
        <Route path='/monitor' exact element={<Monitor />} />
      </Route>
    </Routes>
  </Suspense>
)

const router = createBrowserRouter([{ path: '*', Component: MyRoutes }])
const AppRoutes = () => (<RouterProvider router={router} />)

export default AppRoutes
