import api from '../api'

const checkSession = async ({ id, token }) => {
  return await api.post('/session/check', { userId: id, token })
}

const removeSession = async ({ token }) => {
  return await api.post(`/session/${token}`)
}

export { checkSession, removeSession }
