import axios from 'axios'
import config from '../config'

const api = axios.create({
  baseURL: config[process.env.REACT_APP_ENV].api,
  headers: { token: config[process.env.REACT_APP_ENV].token }
})

api.interceptors.request.use((config) => {
  if (!config.headers.authorization) {
    const token = window.localStorage.getItem('clarity:token')
    if (token) {
      const type = window.localStorage.getItem('clarity:type')
      config.headers.authorization = `Bearer ${token}`
      config.headers.type = type
    }
  }
  return config
}, (error) => { return Promise.reject(error) })

api.interceptors.response.use(response => response, error => {
  console.log('error', error)
  if (error.response.status === 401) {
    // window.location.href = '/'
    // window.localStorage.clear()
    // socket.disconnect()
    // window.location.href = '/'
    // return {}
  }
})

export default api

const blobAPI = axios.create({
  baseURL: config[process.env.REACT_APP_ENV].blob,
  responseType: 'blob',
  headers: {
    'Content-Type': 'multipart/form-data',
    token: config[process.env.REACT_APP_ENV].token
  }
})

const fileAPI = axios.create({
  baseURL: config[process.env.REACT_APP_ENV].api,
  headers: {
    'Content-Type': 'multipart/form-data',
    token: config[process.env.REACT_APP_ENV].token
  }
})

const healthAPI = axios.create({
  baseURL: config[process.env.REACT_APP_ENV].socket,
  headers: { token: config[process.env.REACT_APP_ENV].token }
})

export { blobAPI, fileAPI, healthAPI }
