import React, { useState, useCallback, useEffect } from 'react'
import { Fade, Avatar, Badge, Menu, MenuItem, SwipeableDrawer as MuiDrawer, Button, AppBar, Toolbar, Typography, Box, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { Menu as MenuIcon, Sun, Moon } from 'react-feather'
// import useMediaQuery from '@mui/material/useMediaQuery'
// import { NavLink, useNavigate } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

import { styled } from '@mui/material/styles'

import { useAuth, useTheme } from '../hooks'
import { healthAPI } from '../services/api'
import socket from '../services/socket'

import packa from '../../package.json'

const drawerWidth = 240

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`
  }
})

const StyledBadge = styled(Badge)(({ theme, con }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: con === 'true' ? '#44b700' : '#b70000',
    color: con === 'true' ? '#44b700' : '#b70000',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
}))

// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.common.white, 0.25)
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: '100%',
//   [theme.breakpoints.up('sm')]: { marginLeft: theme.spacing(3), width: 'auto' }
// }))

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center'
// }))

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('md')]: {
//       width: '20ch'
//     }
//   }
// }))

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: 555,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

const Navigation = ({ sideBar, children }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { user, type, signOut } = useAuth()

  const isMenuOpen = Boolean(anchorEl)
  // const navigate = useNavigate()
  const { dark, handleDark } = useTheme()

  // const smScreen = useMediaQuery(theme => theme.breakpoints.up('sm'))

  const [version, setVersion] = useState('')
  const [isConnected, setIsConnected] = useState(true)

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const getVersion = useCallback(async () => {
    const response = await healthAPI.get('/health')
    setVersion(response.data.backend)
  }, [])

  const renderMenu = (
    <Menu
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleMenuClose}
      anchorEl={anchorEl}
      open={isMenuOpen}
      keepMounted
      id='menu'
    >
      <MenuItem disabled>
        {user.name}
      </MenuItem>
      <MenuItem component={NavLink} to='/trocar-senha'>
        Trocar senha
      </MenuItem>
      <MenuItem onClick={signOut}>
        Sair
      </MenuItem>
      <MenuItem disabled>
        <Typography variant='caption'>
          {type} {`[ F${packa.version} - B${version} ]`}
        </Typography>
      </MenuItem>
    </Menu>
  )

  const [open, setOpen] = useState(() => {
    return (window.localStorage.getItem('clarity:sidebar') === 'true')
  })

  const handleDrawer = useCallback(() => {
    window.localStorage.setItem('clarity:sidebar', !open)
    setOpen(!open)
  }, [open])

  const onConnect = () => { setIsConnected(true) }
  const onDisconnect = () => { setIsConnected(false) }

  useEffect(() => {
    socket.on('connect', onConnect)
    socket.on('disconnect', onDisconnect)
    getVersion()
  }, [])

  const modules = user.company.modules.split(' ')
  console.log({ modules })

  const routes = {
    admin: {
      all: [
        { path: '/usuarios', name: 'Usuários' },
        { path: '/pacientes', name: 'Pacientes' },
        { path: '/senha', name: 'Senhas' }
      ],
      agendamento: [
        { path: '/pedidos', name: 'Pedidos' }
      ],
      atendimento: [],
      frequencia: [
        { path: '/frequencias', name: 'Frequência' }
      ]
    },
    call: {
      all: [],
      agendamento: [
        { path: '/pedidos', name: 'Pedidos' }
      ],
      atendimento: [],
      frequencia: []
    }
  }

  return (
    <Box sx={{ display: 'flex', height: '100vh' }} key='home'>
      <AppBar enableColorOnDark position='fixed' open={open} elevation={0} color={dark ? 'secondary' : 'primary'}>
        <Toolbar>
          <Button size='large' sx={{ color: '#fff', ml: -1 }} variant='container' component={NavLink} to='/inicio'>
            <Typography variant='h6' component='div' sx={{ display: { sm: 'flex' } }} color={dark ? 'primary' : 'secondary'}>
              CLARITY
              {/* Suptile */}
              <Typography variant='caption' sx={{ display: { xs: 'none', sm: 'block' }, ml: 1, mt: 1 }} color={dark ? 'primary' : 'secondary'}>
                {user.company.name}
              </Typography>
            </Typography>
          </Button>
          <Fade in appear timeout={250}>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>

              {routes[type].all.map((route) => (
                <Button
                  key={route.path}
                  sx={{ color: '#fff' }}
                  component={NavLink}
                  to={route.path}
                >
                  {route.name}
                </Button>
              ))}

              {modules.map((module, index) => (
                <React.Fragment key={index}>
                  {routes[type][module].map((route) => (
                    <Button
                      key={route.path}
                      sx={{ color: '#fff' }}
                      component={NavLink}
                      to={route.path}
                    >
                      {route.name}
                    </Button>
                  ))}
                </React.Fragment>
              ))}

              {user.superadmin && (
                <Button sx={{ color: '#ffffff' }} component={NavLink} to='/empresas'>
                  Empresas
                </Button>
              )}

            </Box>
          </Fade>
          <Box sx={{ flexGrow: 1 }} />
          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase placeholder='Pesquisar...' inputProps={{ 'aria-label': 'search' }} />
          </Search> */}
          <Fade in appear timeout={250}>
            <Box>
              <IconButton onClick={handleDark}>
                {dark ? <Sun size={20} /> : <Moon size={20} />}
              </IconButton>
              <IconButton
                size='large'
                edge='end'
                aria-label='account of current user'
                aria-controls='menu'
                aria-haspopup='true'
                onClick={handleProfileMenuOpen}
                color='inherit'
              >
                <StyledBadge anchorOrigin={{ vertical: 'top', horizontal: 'right' }} con={`${isConnected}`} overlap='circular' variant='dot'>
                  <Avatar />
                </StyledBadge>
              </IconButton>
            </Box>
          </Fade>
          <Fade in appear timeout={250}>
            <IconButton color='inherit' aria-label='open drawer' edge='start' onClick={handleDrawer} sx={{ ml: 2, display: { sm: 'none' } }}>
              <MenuIcon />
            </IconButton>
          </Fade>
        </Toolbar>
      </AppBar>
      {renderMenu}

      <Drawer
        sx={{ display: { xs: 'block', sm: 'none' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        ModalProps={{ keepMounted: true }}
        onClose={handleDrawer}
        onOpen={handleDrawer}
        variant='temporary'
        anchor='right'
        open={open}
      >
        <Box onClick={handleDrawer} sx={{ textAlign: 'center' }}>
          <Typography variant='h6' sx={{ py: 1.4, background: '#303030' }} color={dark ? 'primary' : 'secondary'}>
            CLARITY
          </Typography>
          <List>
            {/* {routes[type].map((route) => (
              <ListItem key={route.path} disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }} component={NavLink} to={route.path}>
                  <ListItemText primary={route.name} />
                </ListItemButton>
              </ListItem>
            ))} */}
          </List>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <List>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} disabled>
              <ListItemText primary={`${type} ${`[ F${packa.version} - B${version} ]`}`} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1, height: '100vh', overflow: 'auto', p: 3, pt: 9 }}>
        {children}
      </Box>
    </Box>
  )
}

export default Navigation
