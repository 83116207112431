import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'

const envColors = {
  production: {
    primary: '#c6d32f',
    lightPrimary: '#B8DE21',
    secondary: '#303030',
    lightSecondary: '#575757'
  },
  development: {
    primary: '#2196f3',
    secondary: '#303030'
  }
}

const colors = envColors[process.env.REACT_APP_ENV]

const theme = createTheme({})

const customTheme = createTheme(theme, {
  // TODO REMOVE
  // rootDark: {
  //   display: 'flex',
  //   minWidth: '100%',
  //   height: '100%'
  // },
  // rootLight: {
  //   display: 'flex',
  //   minWidth: '100%',
  //   height: '100%'
  // },
  container: {
    minWidth: '100%',
    maxWidth: '100%'
  },
  paper: {
    padding: theme.spacing(1),
    overflow: 'auto',
    height: '100%'
  },
  paperZero: {
    padding: theme.spacing(0),
    overflow: 'auto',
    height: '100%'
  },
  nenhumBox: {
    alignItens: 'center',
    textAlign: 'center',
    height: '90%',
    justifyContent: 'center',
    paddingBottom: 20
  },
  dragOrClick: {
    margin: '16px',
    padding: '16px',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '95%',
    justifyContent: 'center',
    alignItens: 'center',
    border: '1px dashed #ccc',
    textAlign: 'center'
  }
})

const dark = createTheme({
  ...customTheme,
  palette: {
    mode: 'dark',
    primary: {
      main: colors.primary
    },
    secondary: {
      main: colors.secondary
    },
    error: {
      main: red.A400
    },
    white: {
      main: '#fff'
    }
  }
})

const light = createTheme({
  ...customTheme,
  palette: {
    mode: 'light',
    primary: {
      main: colors.primary
    },
    secondary: {
      main: colors.secondary
    },
    error: {
      main: red.A400
    }
  }
})

export { dark, light, colors }
