const userNavigationTypes = {
  all: ['admin', 'call', 'medic'],
  medic: ['medic'],
  call: ['call'],
  admin: ['admin']
}

const checkUserType = (routeTypes, userTypes) => {
  if (userNavigationTypes[routeTypes].indexOf(userTypes) > -1) { return true }
  return false
}

const userArrayTypes = [
  ['admin', 'Administrador'],
  ['call', 'Callcenter'],
  ['medic', 'Médico']
]

const userTypes = {
  admin: 'Administrador',
  call: 'Callcenter',
  medic: 'Médico'
}

export {
  checkUserType,
  userArrayTypes,
  userTypes
}
