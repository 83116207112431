import React from 'react'
import { Button, Avatar, Drawer as MuiDrawer, AppBar, Toolbar, Typography, Box, IconButton, List, Link, Badge } from '@mui/material'
import { Menu as MenuIcon, MoreVertical, ChevronLeft, HelpCircle, Headphones } from 'react-feather'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled } from '@mui/material/styles'
import { NavLink } from 'react-router-dom'

import { useTheme } from '../../hooks'

const StyledBadge = styled(Badge)(({ theme, con }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: con === 'true' ? '#44b700' : '#b70000',
    color: con === 'true' ? '#44b700' : '#b70000',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
}))

const Loading = () => {
  const { dark } = useTheme()

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component='static'>
        <Toolbar>
          <Button size='large' sx={{ color: '#fff', ml: -1 }} variant='container'>
            <Typography variant='h6' component='div' sx={{ display: { sm: 'flex' } }} color='primary'>
              CLARITY
            </Typography>
          </Button>
          <Box sx={{ flexGrow: 1 }} />

          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase placeholder='Pesquisar...' inputProps={{ 'aria-label': 'search' }} />
          </Search> */}

          <Box>
            <IconButton
              size='large'
              edge='end'
              aria-label='account of current user'
              aria-controls='menu'
              aria-haspopup='true'
              color='inherit'
            >
              <StyledBadge anchorOrigin={{ vertical: 'top', horizontal: 'right' }} con={`${true}`} overlap='circular' variant='dot'>
                <Avatar />
              </StyledBadge>
            </IconButton>
          </Box>

          <IconButton color='inherit' aria-label='open drawer' edge='start' sx={{ ml: 2, display: { sm: 'none' } }}>
            <MenuIcon />
          </IconButton>

        </Toolbar>
      </AppBar>
      <Box component='main' sx={{ flexGrow: 1, height: '100vh', overflow: 'auto', pt: 8, m: 1 }} />
    </Box>
  )
}

export default Loading
